import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import {
  Box,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { fetchShops } from "redux/actions/shop";
import { fetchRecruitingMedia } from "redux/actions/recruitingMedia";
import GuestAnalyticsRecruitingMediaLtvApi from "api/GuestAnalyticsRecruitingMediaLtvApi";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import MathUtils from "utils/MathUtils";
type LtvData = {
  totalPrice: number;
  totalCount: number;
  totalProfit: number;
  totalCastFee: number;
  guestCount: number;
  activeGuestCount: number;
  newGuestCount: number;
};
const colorList = [
  "#801A86",
  "#8FE388",
  "#264653",
  "#2a9d8f",
  "#e9c46a",
  "#f4a261",
  "#e63946",
  "#f1faee",
  "#9b2226",
  "#d9ed92",
];
const GuestAnalyticsRecruitingMediaLtv = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const shops = useSelector((state) => state.shop);
  const recruitingMedia = useSelector((state) => state.recruitingMedia);
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ltvData, setLtvData] = useState<{
    [month: string]: {
      [mediaId: number]: LtvData;
    };
  }>({});
  const isDisplayMonth = (month: string) => {
    return (
      DateTime.fromJSDate(endDate).diff(
        DateTime.fromFormat(month, "yyyy-MM"),
        "month"
      ).months <= 12
    );
  };
  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchRecruitingMedia(companyId));
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  const onClickSearchData = async () => {
    setIsLoading(true);
    const result = await GuestAnalyticsRecruitingMediaLtvApi.find(companyId, {
      endDate: DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      shopIds: selectShops.filter((shopId) => shopId !== "すべて").map(Number),
    });
    setLtvData(result);
    setIsLoading(false);
  };

  const withdrawalRate = (mediaData: LtvData[]) => {
    return (
      mediaData.reduce(
        (sum, data) => sum + (data?.guestCount - data?.activeGuestCount),
        0
      ) / mediaData.reduce((sum, data) => sum + data?.guestCount, 0)
    );
  };

  const femaleSalaryRate = (mediaData: LtvData[]) => {
    const totalCastFee = mediaData.reduce(
      (sum, data) => sum + data?.totalCastFee,
      0
    );
    const totalPrice = mediaData.reduce(
      (sum, data) => sum + data?.totalPrice,
      0
    );
    return totalCastFee / totalPrice;
  };
  const ltv = (month: string, mediaId: number) => {
    if (!ltvData && !Object.keys(ltvData).length) return 0;
    const monthIndex = Object.keys(ltvData).findIndex(
      (ltvMonth) => ltvMonth === month
    );
    const mediaData: LtvData[] = [];
    for (const month in ltvData) {
      const d = ltvData[month];
      if (d[mediaId]) {
        mediaData.push(d[mediaId]);
      }
    }
    const threeMonthData = mediaData.slice(monthIndex - 3, monthIndex);
    const guestTotalSales = threeMonthData.reduce(
      (sum, data) => sum + data?.totalPrice,
      0
    );
    const guestTotalCount = threeMonthData.reduce(
      (sum, data) => sum + data?.totalCount,
      0
    );
    const guestNewCount = mediaData.reduce(
      (sum, data) => sum + data?.newGuestCount,
      0
    );
    const sale90days = guestTotalSales / guestTotalCount;
    const customerLifeTime = 1 / withdrawalRate(mediaData);
    const revenue90days = sale90days * (1 - femaleSalaryRate(mediaData));

    return (
      revenue90days * customerLifeTime -
      ((recruitingMedia.find((m) => m.recruitingMediaId === mediaId)?.cost ||
        0) *
        12) /
        guestNewCount
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        margin={2}
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            style={{ marginLeft: "10px" }}
            variant={"contained"}
            color={"primary"}
            onClick={() => onClickSearchData()}
          >
            検索
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : !Object.keys(ltvData).length ? (
        <Box marginLeft={3}>
          <Typography>データがありません。検索してください。</Typography>
        </Box>
      ) : (
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                LTV
              </Typography>
              <BarChart
                width={800}
                height={400}
                data={Object.entries(ltvData)
                  .sort(([a], [b]) => (a > b ? 1 : -1))
                  .filter(([month]) => isDisplayMonth(month))
                  .flatMap(([month, monthData]) => {
                    let t = {};
                    Object.entries(monthData).forEach(([recruitingMediaId]) => {
                      const name = recruitingMedia.find(
                        (medium) =>
                          medium.recruitingMediaId === Number(recruitingMediaId)
                      )?.name;
                      if (!name) return;
                      t = {
                        ...t,
                        [`${name}LTV`]: MathUtils.roundToTwo(
                          ltv(month, Number(recruitingMediaId))
                        ),
                      };
                    });
                    return {
                      month,
                      ...t,
                    };
                  })}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    interval={1}
                    tickLine={false}
                    axisLine={{ stroke: "#f5f5f5" }}
                  />
                  <YAxis width={150} />
                  <Tooltip />
                  {recruitingMedia.map((medium, index) => (
                    <>
                      <Bar
                        dataKey={`${medium.name}LTV`}
                        stackId={"a"}
                        fill={colorList[Number(String(index).slice(-1))]}
                      />
                    </>
                  ))}
                </>
              </BarChart>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: "bold", margin: "5px" }}>
                月別総売上
              </Typography>
              <BarChart
                width={800}
                height={400}
                data={Object.entries(ltvData)
                  .sort(([a], [b]) => (a > b ? 1 : -1))
                  .filter(([month]) => isDisplayMonth(month))
                  .flatMap(([month, monthData]) => {
                    let t = {};
                    Object.entries(monthData).forEach(
                      ([recruitingMediaId, data]) => {
                        const name = recruitingMedia.find(
                          (medium) =>
                            medium.recruitingMediaId ===
                            Number(recruitingMediaId)
                        )?.name;
                        if (!name) return;
                        t = {
                          ...t,
                          [`${name}`]: MathUtils.roundToTwo(data.totalPrice),
                        };
                      }
                    );
                    return {
                      month,
                      ...t,
                    };
                  })}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    interval={1}
                    tickLine={false}
                    axisLine={{ stroke: "#f5f5f5" }}
                  />
                  <YAxis width={150} />
                  <Tooltip />
                  {recruitingMedia.map((medium, index) => (
                    <>
                      <Bar
                        dataKey={`${medium.name}`}
                        stackId={"a"}
                        fill={colorList[Number(String(index).slice(-1))]}
                      />
                    </>
                  ))}
                </>
              </BarChart>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography
              variant={"subtitle1"}
              style={{ fontWeight: "bold", margin: "5px" }}
            >
              LTV 年間データ
            </Typography>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              採用媒体別顧客月間累計購入金額
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>採用媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .filter((month) => isDisplayMonth(month))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recruitingMedia.map((recruitingMedia) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[recruitingMedia.recruitingMediaId])
                    .filter(Boolean);
                  return (
                    <TableRow key={recruitingMedia.recruitingMediaId}>
                      <TableCell>{recruitingMedia.name}</TableCell>
                      <TableCell>
                        {mediaData.reduce(
                          (sum, data) => sum + data.totalPrice,
                          0
                        )}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .filter((month) => isDisplayMonth(month))
                        .map((month) => (
                          <TableCell key={`totalPrice_${month}`}>
                            {ltvData[month]?.[recruitingMedia.recruitingMediaId]
                              ?.totalPrice || 0}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              採用媒体別顧客月間平均売上単価
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>採用媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .filter((month) => isDisplayMonth(month))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recruitingMedia.map((recruitingMedia) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[recruitingMedia.recruitingMediaId])
                    .filter(Boolean);
                  return (
                    <TableRow key={recruitingMedia.recruitingMediaId}>
                      <TableCell>{recruitingMedia.name}</TableCell>
                      <TableCell>
                        {MathUtils.roundToTwo(
                          mediaData.reduce(
                            (sum, data) =>
                              sum + data.totalPrice / data.guestCount,
                            0
                          )
                        ) || 0}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .filter((month) => isDisplayMonth(month))
                        .map((month) => (
                          <TableCell key={`averagePrice_${month}`}>
                            {MathUtils.roundToTwo(
                              (ltvData[month]?.[
                                recruitingMedia.recruitingMediaId
                              ]?.totalPrice || 0) /
                                (ltvData[month]?.[
                                  recruitingMedia.recruitingMediaId
                                ]?.guestCount || 0) || 0
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              採用媒体別LTV
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>採用媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .filter((month) => isDisplayMonth(month))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recruitingMedia.map((recruitingMedia) => {
                  return (
                    <TableRow key={recruitingMedia.recruitingMediaId}>
                      <TableCell>{recruitingMedia.name}</TableCell>
                      <TableCell>-</TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .filter((month) => isDisplayMonth(month))
                        .map((month) => (
                          <TableCell key={`average_LTV_${month}`}>
                            {isNaN(
                              MathUtils.roundToTwo(
                                ltv(month, recruitingMedia.recruitingMediaId)
                              )
                            )
                              ? 0
                              : MathUtils.roundToTwo(
                                  ltv(month, recruitingMedia.recruitingMediaId)
                                )}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" margin={2}>
            <Typography
              variant={"subtitle1"}
              style={{ fontWeight: "bold", margin: "5px" }}
            ></Typography>
            <Typography style={{ fontWeight: "bold", margin: "5px" }}>
              採用媒体別顧客月間累計粗利
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>採用媒体名</TableCell>
                  <TableCell>年間累計</TableCell>
                  {Object.keys(ltvData)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .filter((month) => isDisplayMonth(month))
                    .map((month) => (
                      <TableCell key={month}>{month}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recruitingMedia.map((recruitingMedia) => {
                  const mediaData = Object.values(ltvData)
                    .map((data) => data[recruitingMedia.recruitingMediaId])
                    .filter(Boolean);
                  return (
                    <TableRow key={recruitingMedia.recruitingMediaId}>
                      <TableCell>{recruitingMedia.name}</TableCell>
                      <TableCell>
                        {mediaData.reduce(
                          (sum, data) => sum + data.totalProfit,
                          0
                        )}
                      </TableCell>
                      {Object.keys(ltvData)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .filter((month) => isDisplayMonth(month))
                        .map((month) => (
                          <TableCell key={`totalProfit_${month}`}>
                            {ltvData[month]?.[recruitingMedia.recruitingMediaId]
                              ?.totalProfit || 0}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GuestAnalyticsRecruitingMediaLtv;
