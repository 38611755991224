import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import {
  addCast,
  bulkInsertCast,
  deleteCast,
  fetchCastWithPagination,
  updateCast,
} from "redux/actions/cast";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CastRes from "types/res/cast/CastRes";
import { FormType } from "components/FormField";
import { fetchArea } from "redux/actions/area";
import CreateCastReq from "types/req/cast/CreateCastReq";
import UpdateCastReq from "types/req/cast/UpdateCastReq";
import { simpleFetchGuest } from "redux/actions/guest";
import { fetchCastCategory } from "redux/actions/castCategory";
import EnumUtils from "utils/EnumUtils";
import StaffRole from "types/enum/StaffRole";
import { CsvFieldType } from "utils/CsvUtils";
import { Box, Button, Link, TextField, Typography } from "@material-ui/core";
import CsvImport from "components/CsvImport";
import EnvironmentUtils from "utils/EnviromentUtils";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import MultipleSelect from "components/MultipleSelect";
import CastApi from "api/CastApi";
import toastActions, { ToastType } from "redux/actions/toast";
import { fetchRecruitingMedia } from "redux/actions/recruitingMedia";

const headers: { key: keyof CastRes; label: string }[] = [
  { key: "name", label: "本名" },
  { key: "displayName", label: "TasTeck表示名" },
  { key: "castCategoryId", label: "キャスト区分" },
  { key: "email", label: "メールアドレス" },
  { key: "tel", label: "電話番号" },
  { key: "address", label: "住所" },
  { key: "remarks", label: "備考" },
  { key: "sort", label: "並び順" },
  { key: "entryDate", label: "入店日" },
  { key: "closingDate", label: "退店日" },
  { key: "invoiceRate", label: "インボイス" },
  { key: "recruitingMediaId", label: "採用媒体" },
  { key: "shops", label: "所属店舗" },
  { key: "castNames", label: "源氏名" },
  { key: "ngAreas", label: "NGエリア" },
  { key: "ngGuests", label: "NGゲスト" },
  { key: "ngGuests", label: "" },
];

const Cast: React.FC = () => {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.account.staff.companyId);
  const staffRole = useSelector((state) => state.account.staff.role);
  const shops = useSelector((state) => state.shop);
  const casts = useSelector((state) => state.cast);
  const castCategories = useSelector((state) => state.castCategory);
  const areas = useSelector((state) => state.area);
  const guests = useSelector((state) => state.guest);
  const recruitingMedia = useSelector((state) => state.recruitingMedia);
  const [openCsvImport, setOpenCsvImport] = useState(false);
  const [name, setName] = useState<string>();
  const [displayName, setDisplayName] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectCastCategories, setSelectCastCategories] = useState<string[]>(
    []
  );
  const [selectRecruitingMedia, setSelectRecruitingMedia] = useState<string[]>(
    []
  );
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchArea(companyId));
    dispatch(simpleFetchGuest(companyId));
    dispatch(fetchCastCategory(companyId));
    dispatch(fetchRecruitingMedia(companyId));
    const apiCall = async () => {
      const res = await CastApi.count(companyId, {
        limit,
        offset,
        name: name,
        displayName: displayName,
        tel: tel,
        shopIds:
          selectShops.includes("すべて") || selectShops.length === 0
            ? undefined
            : selectShops.map(Number),
        castCategoryIds:
          selectCastCategories.includes("すべて") ||
          selectCastCategories.length === 0
            ? undefined
            : selectCastCategories.map(Number),
      });
      setTotal(res);
    };
    apiCall();
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(
      fetchCastWithPagination(companyId, {
        limit,
        offset,
        name: name,
        displayName: displayName,
        tel: tel,
        shopIds:
          selectShops.includes("すべて") || selectShops.length === 0
            ? undefined
            : selectShops.map(Number),
        castCategoryIds:
          selectCastCategories.includes("すべて") ||
          selectCastCategories.length === 0
            ? undefined
            : selectCastCategories.map(Number),
        recruitingMediaIds:
          selectRecruitingMedia.includes("すべて") ||
          selectRecruitingMedia.length === 0
            ? undefined
            : selectRecruitingMedia.map(Number),
      })
    );
  }, [limit, offset, companyId]);

  useEffect(() => {
    setSelectShops([
      ...shops.map((shop) => String(shop.shopId)),
      "未設定",
      "すべて",
    ]);
  }, [shops]);

  useEffect(() => {
    setSelectCastCategories([
      ...castCategories.map((castCategory) =>
        String(castCategory.castCategoryId)
      ),
      "未設定",
      "すべて",
    ]);
  }, [castCategories]);

  useEffect(() => {
    setSelectRecruitingMedia([
      ...recruitingMedia.map((r) => String(r.recruitingMediaId)),
      "未設定",
      "すべて",
    ]);
  }, [recruitingMedia]);

  const csvHeader = [
    {
      label: "本名",
      key: "name",
      type: CsvFieldType.Text,
    },
    {
      label: "表示名",
      key: "displayName",
      type: CsvFieldType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: CsvFieldType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: CsvFieldType.Text,
    },
    {
      label: "パスワード",
      key: "password",
      type: CsvFieldType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: CsvFieldType.Text,
    },
    {
      label: "備考",
      key: "remarks",
      type: CsvFieldType.Text,
    },
    {
      label: "並び順",
      key: "sort",
      type: CsvFieldType.Number,
    },
    {
      label: "入店日",
      key: "entryDate",
      type: CsvFieldType.Date,
    },
    {
      label: "退店日",
      key: "closingDate",
      type: CsvFieldType.Date,
    },
    {
      label: "インボイス",
      key: "invoiceRate",
      type: CsvFieldType.Number,
    },
    {
      label: "店舗",
      key: "shops",
      type: CsvFieldType.Array,
      relation: shops,
      relationId: "shopId",
      relationKey: "name",
    },
    {
      label: "NGエリア",
      key: "ngAreas",
      type: CsvFieldType.Array,
      relation: areas,
      relationId: "areaId",
      relationKey: "name",
    },
    {
      label: "NGゲスト",
      key: "ngGuests",
      type: CsvFieldType.Array,
      relation: guests,
      relationId: "guestId",
      relationKey: "tel",
    },
    {
      label: "キャスト区分",
      key: "castCategoryId",
      type: CsvFieldType.Object,
      relation: castCategories,
      relationId: "castCategoryId",
      relationKey: "name",
    },
  ];

  const forms = [
    {
      label: "名前",
      key: "name",
      type: FormType.Text,
    },
    {
      label: "表示名",
      key: "displayName",
      type: FormType.Text,
    },
    {
      label: "メールアドレス",
      key: "email",
      type: FormType.Text,
    },
    {
      label: "電話番号",
      key: "tel",
      type: FormType.Text,
    },
    {
      label: "パスワード",
      key: "password",
      type: FormType.Text,
    },
    {
      label: "住所",
      key: "address",
      type: FormType.TextArea,
    },
    {
      label: "備考",
      key: "remarks",
      type: FormType.TextArea,
    },
    {
      label: "並び順",
      key: "sort",
      type: FormType.Number,
    },
    {
      label: "入店日",
      key: "entryDate",
      type: FormType.Date,
    },
    {
      label: "退店日",
      key: "closingDate",
      type: FormType.Date,
    },
    {
      label: "インボイス",
      key: "invoiceRate",
      type: FormType.Number,
    },
    {
      label: "採用媒体",
      key: "recruitingMediaId",
      type: FormType.Select,
      options: recruitingMedia.map((r) => ({
        id: r.recruitingMediaId,
        name: r.name,
      })),
    },
    {
      label: "店舗",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: Number(s.shopId), name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
    {
      label: "NGエリア",
      key: "ngAreas",
      type: FormType.AutoCompleteMultiOptions,
      options: areas.map((a) => ({ id: Number(a.areaId), name: a.name })),
      value: (val: any) => val.map((a: any) => a.areaId),
    },
    {
      label: "NGゲスト",
      key: "ngGuests",
      type: FormType.AutoCompleteMultiOptions,
      options: guests.map((g) => ({
        id: g.guestId,
        name: `${g.name}(${g.tel})`,
      })),
      value: (val: any) => val.map((g: any) => g.guestId),
    },
    {
      label: "キャスト区分",
      key: "castCategoryId",
      type: FormType.Select,
      options: castCategories.map((c) => ({
        id: c.castCategoryId,
        name: c.name,
      })),
    },
  ];

  const onClickSearch = async () => {
    dispatch(
      fetchCastWithPagination(companyId, {
        limit,
        offset,
        name: name,
        displayName: displayName,
        tel: tel,
        shopIds:
          selectShops.includes("すべて") || selectShops.length === 0
            ? undefined
            : selectShops.map(Number),
        castCategoryIds:
          selectCastCategories.includes("すべて") ||
          selectCastCategories.length === 0
            ? undefined
            : selectCastCategories.map(Number),
        recruitingMediaIds:
          selectRecruitingMedia.includes("すべて") ||
          selectRecruitingMedia.length === 0
            ? undefined
            : selectRecruitingMedia.map(Number),
      })
    );
    const res = await CastApi.count(companyId, {
      limit,
      offset,
      name: name,
      displayName: displayName,
      tel: tel,
      shopIds: selectShops.includes("すべて")
        ? undefined
        : selectShops.map(Number),
      castCategoryIds: selectCastCategories.includes("すべて")
        ? undefined
        : selectCastCategories.map(Number),
      recruitingMediaIds: selectRecruitingMedia.includes("すべて")
        ? undefined
        : selectRecruitingMedia.map(Number),
    });
    setTotal(res);
    setOffset(0);
  };

  const sendEmailCast = async (castId: number) => {
    const result = window.confirm("本当にメール送信しますか？");
    if (result) {
      await CastApi.sendMailCast(companyId, castId);
      await dispatch(
        toastActions.showToast({
          text: "メール送信しました",
          type: ToastType.Success,
        })
      );
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={1}
        marginRight={2}
      >
        <Box display="flex" alignItems="center">
          <TextField
            label="名前"
            style={{ margin: "10px", width: "200px" }}
            value={name || ""}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="表示名"
            style={{ margin: "10px", width: "200px" }}
            value={displayName || ""}
            onChange={(event) => setDisplayName(event.target.value)}
          />
          <TextField
            label="電話番号"
            style={{ margin: "10px", width: "200px" }}
            value={tel || ""}
            onChange={(event) => setTel(event.target.value)}
          />
          <MultipleSelect
            id={"select-multiple-select"}
            title={"店舗"}
            options={shops.map((shop) => ({
              id: shop.shopId,
              name: shop.name,
            }))}
            selectValues={selectShops}
            setSelectValues={setSelectShops}
          />
          <MultipleSelect
            id={"select-multiple-class"}
            title={"キャスト区分"}
            options={castCategories.map((castCategory) => ({
              id: castCategory.castCategoryId,
              name: castCategory.name,
            }))}
            selectValues={selectCastCategories}
            setSelectValues={setSelectCastCategories}
          />
          <MultipleSelect
            id={"select-multiple-recruiting-media"}
            title={"採用媒体"}
            options={recruitingMedia.map((rc) => ({
              id: rc.recruitingMediaId,
              name: rc.name,
            }))}
            selectValues={selectRecruitingMedia}
            setSelectValues={setSelectRecruitingMedia}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onClickSearch()}
            style={{ marginLeft: "30px" }}
          >
            検索
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(EnvironmentUtils.getCastDomain())}
              style={{ marginLeft: "30px" }}
            >
              キャストモバイルへ
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCsvImport((prev) => !prev)}
              style={{ marginLeft: "30px" }}
            >
              csvインポート
            </Button>
          </Box>
        </Box>
      </Box>
      {openCsvImport && (
        <CsvImport
          templatePath={"/csv/cast.csv"}
          templateName={"cast_template.csv"}
          csvHeader={csvHeader}
          addType={CreateCastReq}
          addFunc={(formData) => bulkInsertCast(companyId, formData)}
        />
      )}
      <CastTable
        fullWidth
        title={"在籍"}
        formId="castForm"
        forms={forms}
        defaultValue={{ invoiceRate: 10 }}
        addFunc={(formData) => addCast(companyId, formData)}
        updateFunc={(formData) => updateCast(companyId, formData)}
        deleteFunc={(item) =>
          deleteCast(companyId, {
            castId: item?.castId,
          })
        }
        invisibleDeleteIcon={
          EnumUtils.mapToEnum(StaffRole, staffRole) ===
          StaffRole.clientShopEmployee
        }
        // bulkDeleteFunc={(item) =>
        //   bulkDeleteCast(
        //     companyId,
        //     item.map((value: CastRes) => ({ castId: value.castId }))
        //   )
        // }
        rows={headers}
        data={casts}
        addType={CreateCastReq}
        updateType={UpdateCastReq}
        setLimit={setLimit}
        totalCount={total}
        setOffset={setOffset}
        offset={offset}
        limit={limit}
        values={[
          (s) => s.name,
          (s) => s.displayName,
          (s) => s.castCategory?.name || "未設定",
          (s) => s.email,
          (s) => s.tel,
          (s) => s.address,
          (s) => s.remarks || "未設定",
          (s) => s.sort || "未設定",
          (s) =>
            s.entryDate
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.entryDate,
                  FORMAT_TYPE.YEAR_DAY
                )
              : "未設定",
          (s) =>
            s.closingDate
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  s.closingDate,
                  FORMAT_TYPE.YEAR_DAY
                )
              : "未設定",
          (s) => (s.invoiceRate ? `${s.invoiceRate}%` : "未設定"),
          (s) => (s.recruitingMedia ? `${s.recruitingMedia.name}` : "未設定"),
          (s) =>
            s.shops
              .map(
                (shopRes) =>
                  shops.find((shop) => shop.shopId === shopRes.shopId)?.name
              )
              .join("/") || "所属なし",
          (s) =>
            s.castNames?.length ? (
              <>
                {s.castNames.map((castNameRes, index) => (
                  <Box display={"flex"} key={castNameRes.castNameId}>
                    <Link href={`/castName?editId=${castNameRes.castNameId}`}>
                      {castNameRes.name}
                    </Link>
                    {index + 1 !== s.castNames.length && (
                      <Typography>/</Typography>
                    )}
                  </Box>
                ))}
              </>
            ) : (
              <Link href={"/castName"}>未設定</Link>
            ),
          (s) =>
            s.ngAreas
              ?.map(
                (areaRes) =>
                  areas.find((area) => area.areaId === areaRes.areaId)?.name
              )
              .join("/") || "所属なし",
          (s) =>
            s.ngGuests
              ?.map(
                (guestRes) =>
                  guests.find((guest) => guest.guestId === guestRes.guestId)
                    ?.name
              )
              .join("/") || "所属なし",
          (s) => (
            <Link href={`/orderList/cast/${s.castId}`} target="_blank">
              接客履歴
            </Link>
          ),
          (s) => (
            <Box
              style={{ width: "120px" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendEmailCast(s.castId)}
              >
                メール送信
              </Button>
            </Box>
          ),
        ]}
      />
    </>
  );
};

const CastTable = styled<
  React.FC<CommonTableProps<CastRes, CreateCastReq, UpdateCastReq>>
>(CommonTable)`
  margin-top: 24px;
`;

export default Cast;
